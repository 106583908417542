import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "our-design-principles"
    }}>{`Our Design Principles`}</h1>
    <h2 {...{
      "id": "simple"
    }}>{`Simple`}</h2>
    <ul>
      <li parentName="ul">{`Use present tense and active voice.`}</li>
      <li parentName="ul">{`Avoid technical jargon.`}</li>
      <li parentName="ul">{`Don’t over-explain, and reduce to the most important information.`}</li>
    </ul>
    <h2 {...{
      "id": "fast"
    }}>{`Fast`}</h2>
    <ul>
      <li parentName="ul">{`Keep it short, simple, and conversational.`}</li>
      <li parentName="ul">{`Less is more. Users don’t have time to read a block of text.`}</li>
      <li parentName="ul">{`Talk to the user in second person–use “You.”`}</li>
    </ul>
    <h2 {...{
      "id": "clever"
    }}>{`Clever`}</h2>
    <ul>
      <li parentName="ul">{`Make it friendly, approachable, and intuitive.`}</li>
      <li parentName="ul">{`Be accurate (know the difference between search vs filter).`}</li>
      <li parentName="ul">{`Be direct. Don’t beat around the bush.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      